<template>
  <div>
    <!-- email sign-up -->
    <email-sign-up
      v-if="sign_up"
      v-on:exit="sign_up=false"
    />
    <!-- email login -->
    <email-login
      v-else-if="login"
      v-on:exit="login=false"
      v-on:switch_to_signup="switch_to_signup"
    />
    <!-- sign up -->
    <div v-else-if="create_account"
         v-on:exit="create_account=false"  class="d-grid gap-2">

      <div class="col-12">
        <strong>
          Already have an account?
        </strong>
        <span>
          <a href="#" @click="create_account=false">Login</a>
        </span>
      </div>

      <h2>Sign up to get amazing insights!</h2>
      <p class="mb-4">
        Sign Up then connect your online accounts to get  personalised business insights in seconds.
      </p>

        <!-- NEW FORM -->
      <template v-if="!sign_up_step_2">
        <strong class="mb-3">
          Tell us a little about you. I am:
        </strong>
        <label><input type="radio" v-model="businessType" id="signUpBusinessWithABN" value="Business with ABN"> A business (with an ABN) interested in my business customers</label>
        <label><input type="radio" v-model="businessType" id="signUpIndividual" value="Individual"> An individual (i.e. not a business) interested in a business</label>
        <label><input type="radio" v-model="businessType" id="signUpOther" value="Other"> Other</label>
        <template v-if="businessType=='Other'">
          <label>Please let us know</label>
          <input v-model="businessOther" type="text" class="form-control">
        </template>

        <button
          class="btn btn-secondary mt-3"
          @click="sign_up_step_2=true"
          style="height: 50px;"
          id="btnSignUpNext"
          :disabled="isDisabled"
        >
          <span class="ml-3">
            Next
          </span>
        </button>

      </template>
      <!-- / New Form -->


      <template v-if="sign_up_step_2">
        <p v-if="signup_title != ''" class="mb-4">{{ signup_title }}</p>
        <label v-if="this.signup_referral_id">Referrer ID: {{ this.signup_referral_id }}</label>
        <button
          class="btn btn-primary w-100"
          @click="GoToLogin()"
          id="btnConnectWithXeroBusiness"
        >
          <img src="https://evenly.com.au/storage/app/media/app/xero-logo.png" style="width:40px" />

          Connect with Xero

        </button>
        <button
          class="btn btn-secondary"
          @click="GoogleLogin()"
          style="height: 50px;"
          id="btnSignUpWithGoogle"
        >
          <img id="circle-white-background" src="https://evenly.com.au/storage/app/media/app/google-login.png" />
          <span class="ml-3">
            Sign up with Google
          </span>
        </button>
        <button
          class="btn btn-secondary"
          @click="sign_up=true"
          style="height: 50px;"
          id="btnSignUpWithEmail"
        >
          <span class="ml-3">
            Sign up with Email
          </span>
        </button>

        <div class="row mt-4">
          <strong>Problems?</strong>
          <!-- <p class="m-0">
            Don't have your Xero details handy?
            <a href="#request-email" target="__blank">CLICK HERE</a>
          </p> -->
          <p class="m-0">
            Don't use Xero?
            <a href="https://evenly.com.au/paypredict/other-software" target="__blank">CLICK HERE</a>
          </p>
        </div>
      </template>
    </div>
    <!-- sign in -->
    <div v-else  class="d-grid gap-2">
      <div class="col-12">
        <strong>
          Need an account?
        </strong>
        <span>
          <a href="#" @click="create_account=true">Sign up</a>
        </span>
      </div>

      <h2>Sign in</h2>

      <button
        class="btn btn-primary"
        @click="GoToLogin()"
        id="btnConnectWithXeroBusiness"
      >
        <img src="@/assets/img/xero-logo.png" style="width:40px" />
        <span class="ml-3">
          Login with Xero
        </span>
      </button>
      <button
        class="btn btn-secondary"
        @click="GoogleLogin()"
        style="height: 50px;"
        id="btnLoginWithGoogle"
      >
        <img id="circle-white-background" src="@/assets/img/google-login.png" />
        <span class="ml-3">
          Login with Google
        </span>
      </button>
       <button
        class="btn btn-secondary"
        @click="login=true"
        style="height: 50px;"
        id="btnLoginWithEmail"
      >
        <span class="ml-3">
          Login with Email
        </span>
      </button>

    </div>
  </div>
</template>

<script>
import EmailSignUp from "@/components/EmailSignUp.vue";
import EmailLogin from "@/components/EmailLogin.vue";
export default {
  name: "referlogin",
  computed: {
    signup_type() {
      return this.$store.state.signup_type;
    },
    signup_referral_id() {
      return this.$store.state.signup_referral_id;
    },
    signup_title() {
      if (["advisor", "referred_by_advisor"].includes(this.signup_type)) {
        return "Sign up to PayPredict for free, forever! Thanks to your advisor.";
      }
      return "";
    },
    isDisabled() {
      return !this.businessType;
    }
  },
  components: {
    EmailSignUp,
    EmailLogin,
  },
  data() {
    return {
      sign_up: false,
      sign_up_step_2: false,
      login: false,
      create_account: true,
      businessType: null,
      businessOther: null,
    };
  },
  methods: {
    GoToLogin() {
      location.assign("/login/xero?intent=paypredict");
    },
    GoogleLogin() {
      location.assign("/login/google?intent=paypredict");
    },
    switch_to_signup() {
      this.login = false;
      this.sign_up = true;
    },
  },
  mounted() {
      this.$nextTick(() => window.dataLayer.push({event: 'app-modal-view', app_modal: this.$options.name,}))
  },
};
</script>
<style type="text/css" scoped>
#circle-white-background {
  background: white;
  padding: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
</style>
